import React from "react";
const IconWebsite = (props) => {
    return (
        <a href={props.URL} target="opener">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <title>Website</title>
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2842 6.7156 29.9999 14.9998 30C14.9998 30 14.9997 30 14.9998 30C23.284 30 30 23.2843 30 15.0001C30 6.71581 23.2843 0 15 0ZM11.8861 3.34432C7.19282 4.59495 3.62529 8.60496 3.02711 13.5308H8.4397C8.83126 9.91646 10.0112 6.44012 11.8861 3.34432ZM18.1133 3.34416C19.9883 6.44001 21.1682 9.9164 21.5598 13.5308H26.9729C26.3747 8.60476 22.8069 4.59462 18.1133 3.34416ZM18.6018 13.5308C18.1781 10.0891 16.9426 6.79845 14.9998 3.93028C13.0569 6.79845 11.8214 10.0891 11.3978 13.5308H18.6018ZM11.3978 16.4693H18.6017C18.1781 19.911 16.9426 23.2016 14.9998 26.0697C13.0569 23.2016 11.8214 19.911 11.3978 16.4693ZM8.43971 16.4693H3.02713C3.62537 21.3951 7.19287 25.4051 11.8861 26.6557C10.0112 23.5599 8.8313 20.0836 8.43971 16.4693ZM18.1133 26.6558C19.9883 23.56 21.1682 20.0837 21.5598 16.4693H26.9729C26.3746 21.3953 22.8068 25.4054 18.1133 26.6558Z"
                    fill="#252627" />
            </svg>
        </a>
    )
}

export default IconWebsite;